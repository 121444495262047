
































































import Vue from 'vue';
import { IMoodTracker, MoodType } from '@/scripts/store/modules/tools/moods/types';
import worried from '@/views/tools/moods/components/moodIcons/worried.vue';
import sad from '@/views/tools/moods/components/moodIcons/sad.vue';
import anxious from '@/views/tools/moods/components/moodIcons/anxious.vue';
import stressed from '@/views/tools/moods/components/moodIcons/stressed.vue';
import guilt from '@/views/tools/moods/components/moodIcons/guilt.vue';
import angry from '@/views/tools/moods/components/moodIcons/angry.vue';
import relaxed from '@/views/tools/moods/components/moodIcons/relaxed.vue';
import confident from '@/views/tools/moods/components/moodIcons/confident.vue';
import happy from '@/views/tools/moods/components/moodIcons/happy.vue';
import PrimaryButton from '@/views/components/button/primary-button.vue';

export default Vue.extend({
  name: 'addMoodEmotions',
  components: {
    PrimaryButton,
    worried,
    sad,
    anxious,
    stressed,
    guilt,
    angry,
    relaxed,
    confident,
    happy,
  },
  props: {
    submit: {
      type: Function,
      required: true,
    },
    form: {
      type: Object as () => IMoodTracker,
      required: true,
    },
    cancellable: {
      type: Boolean,
      required: false,
      default: false,
    },
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      moodForm: this.form,
    };
  },
  computed: {
    disableNext(): boolean {
      return this.moodForm.moodIntensities ? Object.values(this.moodForm.moodIntensities).every(value => !value || !value.selected) : true;
    },
  },
  methods: {
    selectEmotion(mood: string) {
      if (!this.moodForm.moodIntensities) return;
      const moodSelected = this.moodForm.moodIntensities[mood as keyof MoodType]?.selected;
      if (moodSelected === undefined) return;
      this.moodForm.moodIntensities[mood as keyof MoodType] = { selected: !moodSelected, value: null };
      this.$emit('update:form', this.moodForm);
    },
    cancel() {
      this.$router.go(-1);
    },
  },
});
