<template>
<div class="mood-icon sad" @click="click('sad')" :class="{'is-active': active}">
  <svg class="svg-icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
       x="0px" y="0px"
       viewBox="0 0 51 51" style="enable-background:new 0 0 51 51;" xml:space="preserve">
    <path class="st32" d="M25.5,2.5c12.7,0,23,10.3,23,23s-10.3,23-23,23s-23-10.3-23-23S12.8,2.5,25.5,2.5 M25.5,0.5
      c-13.8,0-25,11.2-25,25s11.2,25,25,25s25-11.2,25-25S39.3,0.5,25.5,0.5L25.5,0.5z"/>
    <path class="st32" d="M25.5,36.6c-4.2,0-10.8,1.3-10.8,4.8c0,2.2,2.4,2.9,10.8,2.9s10.8-0.6,10.8-2.9
      C36.3,37.9,29.7,36.6,25.5,36.6z M25.5,40.5c-2.8,0-5.3-0.4-6.6-0.9c1.6-0.6,4-1.1,6.6-1.1s5,0.5,6.6,1.1
      C30.8,40.1,28.3,40.5,25.5,40.5z"/>
    <path class="st32" d="M41.4,26.7c-0.3-0.9-1.7-1.3-3.9-1.9c-1.9-0.5-4.2-1.1-6.2-2.2c-0.3-0.2-0.7-0.3-1.1-0.2
      c-0.5,0-1,0.5-1,0.9c0,0.3,0.1,0.6,0.3,0.8c1.1,1.5,2.7,2.3,3.9,2.7c1.5,0.5,3.4,0.6,4.5,0.7c0.1,0,0.6,0,1.2,0
      c0.9,0,1.9-0.1,2.2-0.4C41.4,27,41.4,26.8,41.4,26.7z"/>
    <path class="st32" d="M20.8,22.3c-0.4,0-0.7,0-1.1,0.2c-2.1,1.1-4.4,1.7-6.2,2.2c-2.2,0.6-3.6,1-3.9,1.9c0,0.1,0,0.4,0.1,0.5
      c0.3,0.3,1.3,0.4,2.2,0.4c0.6,0,1.2,0,1.2,0c1.1-0.1,3-0.2,4.5-0.7c1.2-0.4,2.8-1.2,3.9-2.7c0.1-0.2,0.3-0.5,0.3-0.8
      C21.8,22.8,21.4,22.4,20.8,22.3z"/>
    <path class="st32" d="M41.2,29.5c-0.2-0.1-0.3-0.1-0.5,0c0,0-2.5,1.7-5.4,1.7c-3,0-5.4-1.7-5.4-1.7c-0.1-0.1-0.3-0.1-0.5,0
      c-0.2,0.1-0.3,0.2-0.3,0.4c0,2,2.7,3.5,6.2,3.5c3.5,0,6.2-1.6,6.2-3.5C41.5,29.8,41.4,29.6,41.2,29.5z"/>
    <path class="st32" d="M21.6,29.5c-0.2-0.1-0.3-0.1-0.5,0c0,0-2.5,1.7-5.4,1.7c-3,0-5.4-1.7-5.4-1.7c-0.1-0.1-0.3-0.1-0.5,0
      c-0.2,0.1-0.3,0.2-0.3,0.4c0,2,2.7,3.5,6.2,3.5c3.5,0,6.2-1.6,6.2-3.5C21.9,29.8,21.8,29.6,21.6,29.5z"/>
  </svg>
  <span class="starling-text font-weight-semi-bold pt-2" :class="{ 'primary--text': active }">{{ $t('app.tools.mood_tracker.tabs.tool.fields.emotions.sad') }}</span>
</div>
</template>

<script>
export default {
  name: 'sadIcon',
  props: {
    click: {
      type: Function,
      required: false,
    },
    active: {
      type: Boolean,
      required: false,
    },
  },
};
</script>
