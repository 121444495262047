<template>
<div class="mood-icon worried" @click="click('worried')" :class="{'is-active': active}">
  <svg class="svg-icon" viewBox="0 0 53 53" xmlns="http://www.w3.org/2000/svg">
    <path class="st32" d="M25.5,2.5c12.7,0,23,10.3,23,23s-10.3,23-23,23s-23-10.3-23-23S12.8,2.5,25.5,2.5 M25.5,0.5
      c-13.8,0-25,11.2-25,25s11.2,25,25,25s25-11.2,25-25S39.3,0.5,25.5,0.5L25.5,0.5z"/>
    <path class="st32" d="M40.3,13.7c-0.9-2.1-3.5-3.6-6.1-3.6c-1.8,0-2.9,0.9-3.3,1.7c-0.2,0.5-0.1,1,0.2,1.4
    c0.4,0.4,1.4,0.3,2.3,0.2c0.4,0,0.7-0.1,1-0.1c1.5,0,3.1,0.5,4.6,1.5c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0,0.4-0.1,0.5-0.2
    C40.3,14.4,40.4,14,40.3,13.7z"/>
    <path class="st32" d="M19.2,11c-0.6-0.1-1.2,0-1.8,0.3c-0.6,0.3-0.9,0.6-1.3,0.8c-0.6,0.5-1.2,0.8-3.5,1.4
    c-0.3,0.1-0.7,0.1-1.1,0.2c-0.3,0-0.6,0.1-0.9,0.1c-0.6,0.1-1.1,0.6-1.1,1.1c0,0.2,0.1,0.8,1.1,1c0.2,0,0.5,0.1,0.8,0.1
    c2,0,6-0.7,8-2.3c0.4-0.3,0.7-0.6,0.8-1.1C20.4,11.7,19.9,11.1,19.2,11z"/>
    <path class="st32" d="M38.2,37c-0.5-0.3-1.1-0.5-1.8-0.6c-2.1-0.3-4.4,0.3-7.1,1.9c-2.1,1.2-6.9,1.4-10.8,1.4
    c-0.6,0-1.4-0.4-1.6-0.6c-0.6-0.5-1.2-0.5-1.6-0.1c-0.5,0.4-0.6,1.1-0.2,1.6c1,1.6,3.5,1.9,4.9,1.9c0.4,0,0.8,0,1.1,0
    c7.4,0,9.4-1.2,12-2.9l0.1-0.1c0.8-0.5,2.3-0.4,3.6-0.2l0.6,0.1c1.1,0.1,1.3-0.6,1.4-0.8C39,37.8,38.6,37.2,38.2,37z M15.5,40.5
    l0.3-0.3l0,0L15.5,40.5C15.5,40.5,15.5,40.5,15.5,40.5z"/>
    <path class="st32" d="M35.2,16.5c-3.1,0-5.6,2.5-5.6,5.6c0,3.1,2.5,5.6,5.6,5.6c3.1,0,5.6-2.5,5.6-5.6
      C40.8,19,38.3,16.5,35.2,16.5z M35.2,25.6c-1.9,0-3.4-1.5-3.5-3.3c0.5,0.5,1.2,0.9,2,0.9c1.5,0,2.8-1.2,2.8-2.8
      c0-0.6-0.2-1.2-0.5-1.6c1.5,0.4,2.7,1.7,2.7,3.4C38.6,24,37.1,25.6,35.2,25.6z"/>
    <path class="st32" d="M15.7,16.5c-3.1,0-5.6,2.5-5.6,5.6c0,3.1,2.5,5.6,5.6,5.6c3.1,0,5.6-2.5,5.6-5.6
      C21.3,19,18.8,16.5,15.7,16.5z M15.7,25.6c-1.9,0-3.4-1.5-3.5-3.3c0.5,0.5,1.2,0.9,2,0.9c1.5,0,2.8-1.2,2.8-2.8
      c0-0.6-0.2-1.2-0.5-1.6c1.5,0.4,2.7,1.7,2.7,3.4C19.2,24,17.7,25.6,15.7,25.6z"/>
  </svg>
  <span class="starling-text font-weight-semi-bold pt-2" :class="{ 'primary--text': active }">{{ $t('app.tools.mood_tracker.tabs.tool.fields.emotions.worried') }}</span>
</div>
</template>

<script>
export default {
  name: 'worriedIcon',
  props: {
    click: {
      type: Function,
      required: false,
    },
    active: {
      type: Boolean,
      required: false,
    },
  },
};
</script>
