<template>
<div class="mood-icon guilt" @click="click('guilt')" :class="{'is-active': active}">
  <svg class="svg-icon" viewBox="0 0 53 53" xmlns="http://www.w3.org/2000/svg">
    <path d="M26.1634 2.24302C39.3714 2.24302 50.0834 12.955 50.0834 26.163C50.0834 39.371 39.3714 50.083 26.1634 50.083C12.9554 50.083 2.24339 39.371 2.24339 26.163C2.24339 12.955 12.9554 2.24302 26.1634 2.24302ZM26.1634 0.163025C11.8114 0.163025 0.163391 11.811 0.163391 26.163C0.163391 40.515 11.8114 52.163 26.1634 52.163C40.5154 52.163 52.1634 40.515 52.1634 26.163C52.1634 11.811 40.5154 0.163025 26.1634 0.163025Z"/>
    <path d="M35.7314 15.659C32.7154 15.659 30.2194 18.467 30.2194 21.795C30.2194 25.227 32.7154 27.931 35.7314 27.931C38.7474 27.931 41.2434 25.123 41.2434 21.795C41.2434 18.467 38.8514 15.659 35.7314 15.659ZM35.7314 26.059C33.7554 26.059 32.1954 24.187 32.1954 21.795C32.1954 19.507 33.7554 17.531 35.7314 17.531C37.7074 17.531 39.2674 19.403 39.2674 21.795C39.2674 24.187 37.7074 26.059 35.7314 26.059Z"/>
    <path d="M35.7314 24.187C36.8227 24.187 37.7074 23.1161 37.7074 21.795C37.7074 20.4739 36.8227 19.403 35.7314 19.403C34.6401 19.403 33.7554 20.4739 33.7554 21.795C33.7554 23.1161 34.6401 24.187 35.7314 24.187Z"/>
    <path d="M22.9394 21.795C22.9394 18.363 20.4434 15.659 17.4274 15.659C14.4114 15.659 11.9154 18.467 11.9154 21.795C11.9154 25.227 14.4114 27.931 17.4274 27.931C20.4434 28.035 22.9394 25.227 22.9394 21.795ZM13.8914 21.795C13.8914 19.507 15.4514 17.531 17.4274 17.531C19.4034 17.531 20.9634 19.403 20.9634 21.795C20.9634 24.083 19.4034 26.059 17.4274 26.059C15.5554 26.059 13.8914 24.187 13.8914 21.795Z"/>
    <path d="M17.4274 24.187C18.5187 24.187 19.4034 23.1161 19.4034 21.795C19.4034 20.4739 18.5187 19.403 17.4274 19.403C16.3361 19.403 15.4514 20.4739 15.4514 21.795C15.4514 23.1161 16.3361 24.187 17.4274 24.187Z"/>
    <path d="M9.42999 17.3069C9.38937 17.1655 9.43975 17.0745 9.54049 16.8925C9.6916 16.6196 9.93371 16.397 10.2164 16.3158C11.4887 15.9502 12.9022 15.5441 14.2639 14.1581C14.6067 13.7536 15.0909 13.3084 15.515 13.1866C16.3729 12.7105 16.9188 13.0127 17.1918 13.1639C17.9099 13.7992 17.5378 14.9008 16.9431 15.7603C16.4995 16.3468 15.0567 17.45 14.3905 17.7945C13.1085 18.3923 11.8363 18.7579 10.2294 18.2249C9.45109 17.9129 9.42023 17.5392 9.42999 17.3069Z"/>
    <path d="M38.5734 17.6045C37.8941 17.1591 36.8123 15.7492 36.5006 15.0831C35.9986 14.1489 35.994 13.0631 36.7984 12.4919C37.0666 12.3014 37.6635 12.1196 38.4337 12.8634C38.8534 13.1705 39.2039 13.6073 39.4549 14.0744C40.3984 15.7439 41.7958 16.4054 42.9639 17.028C43.2235 17.1664 43.4139 17.4345 43.5048 17.733C43.5352 17.8325 43.5655 17.932 43.5266 18.1612C43.4877 18.3905 43.2802 18.7799 42.653 18.8622C40.9011 19.1785 39.7026 18.4564 38.5734 17.6045Z"/>
    <path d="M15.5864 36.4286C15.6909 36.7405 16.1078 37.2598 17.1473 36.9461C20.2658 36.0051 23.4891 35.5839 26.7131 35.5787C30.2491 35.573 33.4742 36.2958 36.1804 37.6434C37.1171 38.0579 37.6362 37.537 37.7401 37.4329C38.0516 37.1204 38.1546 36.4962 37.6337 35.9771C35.1327 32.8611 29.204 32.4546 26.8119 32.3545C26.7079 32.3547 26.4999 32.355 26.2919 32.3553C24.2119 32.3587 19.3242 32.5746 16.2077 34.7636C15.2729 35.4931 15.4821 36.2208 15.5864 36.4286ZM36.0757 37.2276L36.5951 36.8107L36.0757 37.2276Z"/>
  </svg>
  <span class="starling-text font-weight-semi-bold pt-2" :class="{ 'primary--text': active }">{{ $t('app.tools.mood_tracker.tabs.tool.fields.emotions.guilt') }}</span>
</div>
</template>

<script>
export default {
  name: 'guiltIcon',
  props: {
    click: {
      type: Function,
      required: false,
    },
    active: {
      type: Boolean,
      required: false,
    },
  },
};
</script>
