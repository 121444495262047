<template>
<div class="mood-icon relaxed" @click="click('relaxed')" :class="{'is-active': active}">
  <svg class="svg-icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
       x="0px" y="0px"
       viewBox="0 0 51 51" style="enable-background:new 0 0 51 51;" xml:space="preserve">
    <path class="st32" d="M25.5,2.5c12.7,0,23,10.3,23,23c0,12.7-10.3,23-23,23s-23-10.3-23-23C2.5,12.8,12.8,2.5,25.5,2.5 M25.5,0.5
      c-13.8,0-25,11.2-25,25s11.2,25,25,25s25-11.2,25-25S39.3,0.5,25.5,0.5L25.5,0.5z"/>
    <path class="st32" d="M36.2,38.7c-0.1-0.3-0.5-0.8-1.5-0.5c-3,0.9-6.1,1.3-9.2,1.3c-3.4,0-6.5-0.7-9.1-2c-0.9-0.4-1.4,0.1-1.5,0.2
      c-0.3,0.3-0.4,0.9,0.1,1.4c2.4,3,8.1,3.4,10.4,3.5c0.1,0,0.3,0,0.5,0c2,0,6.7-0.2,9.7-2.3C36.5,39.6,36.3,38.9,36.2,38.7z
      M16.5,37.9L16.5,37.9L16,38.3l0,0L16.5,37.9z"/>
    <path class="st32" d="M39.2,22c-0.1-0.1-0.3-0.1-0.5,0c0,0-2.2,1-4.7,1c-2.6,0-4.7-1-4.7-1c-0.1-0.1-0.3-0.1-0.5,0
      c-0.1,0.1-0.2,0.2-0.2,0.4c0,1.5,2.3,2.7,5.4,2.7c3.1,0,5.4-1.2,5.4-2.7C39.4,22.3,39.4,22.1,39.2,22z"/>
    <path class="st32" d="M21.7,22c0,0-2.2,1-4.7,1s-4.7-1-4.7-1c-0.1-0.1-0.3-0.1-0.5,0c-0.1,0.1-0.2,0.2-0.2,0.4
      c0,1.5,2.3,2.7,5.4,2.7s5.4-1.2,5.4-2.7c0-0.2-0.1-0.3-0.2-0.4C22,21.9,21.8,21.9,21.7,22z"/>
    <path class="st32" d="M32,14.6c0.4,0,0.7,0,1,0c1.4,0.2,2.9,0.9,4.2,2c0.1,0.1,0.3,0.2,0.5,0.2c0.1,0,0.3,0,0.4-0.1
      c0.3-0.2,0.4-0.5,0.3-0.9c0,0,0,0,0,0c-0.6-2.1-2.8-3.9-5.3-4.2c-1.7-0.2-2.9,0.4-3.3,1.2c-0.3,0.5-0.2,1,0.1,1.3
      C30.2,14.6,31.1,14.6,32,14.6z"/>
    <path class="st32" d="M12.9,16.7c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0,0.4-0.1,0.5-0.2c1.3-1.1,2.8-1.8,4.2-2c0.3,0,0.6,0,1,0
      c0.9,0,1.8,0,2.2-0.5c0.3-0.4,0.3-0.9,0.1-1.3c-0.4-0.7-1.6-1.4-3.3-1.2c-2.5,0.3-4.7,2.1-5.3,4.2v0
      C12.5,16.2,12.6,16.5,12.9,16.7z"/>
  </svg>
  <span class="starling-text font-weight-semi-bold pt-2" :class="{ 'primary--text': active }">{{ $t('app.tools.mood_tracker.tabs.tool.fields.emotions.relaxed') }}</span>
</div>
</template>

<script>
export default {
  name: 'relaxedIcon',
  props: {
    click: {
      type: Function,
      required: false,
    },
    active: {
      type: Boolean,
      required: false,
    },
  },
};
</script>
