<template>
<div class="mood-icon happy" @click="click('happy')" :class="{'is-active': active}">
  <svg class="svg-icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
       x="0px" y="0px" viewBox="0 0 52 52" style="enable-background:new 0 0 52 52;" xml:space="preserve">
    <path class="st32" d="M26,3c12.7,0,23,10.3,23,23c0,12.7-10.3,23-23,23S3,38.7,3,26C3,13.3,13.3,3,26,3 M26,1C12.2,1,1,12.2,1,26
      c0,13.8,11.2,25,25,25s25-11.2,25-25C51,12.2,39.8,1,26,1L26,1z"/>
    <path class="st32"
          d="M35.1,13.8c1.5,0,2.8,1.8,2.8,4.1c0,2.2-1.2,4.1-2.8,4.1c-1.5,0-2.8-1.8-2.8-4.1C32.4,15.6,33.6,13.8,35.1,13.8 M35.1,11.9c-2.6,0-4.6,2.6-4.6,6s2,6,4.6,6c2.6,0,4.6-2.6,4.6-6S37.7,11.9,35.1,11.9L35.1,11.9z"/>
    <path class="st32" d="M20.6,9.7c-0.5-1.7-2.3-2.4-3.9-2.3c-1.6,0.1-3.3,1.1-3.5,2.8c0,0.3,0.1,0.5,0.3,0.7
      c0.1,0.1,0.3,0.1,0.4,0.1c0.1,0,0.3,0,0.4-0.1c0.4-0.2,1.2-0.6,2.7-0.7c1.5-0.1,2.3,0.2,2.7,0.3h0c0.3,0.1,0.5,0.1,0.7-0.1
      C20.6,10.2,20.7,9.9,20.6,9.7z"/>
    <path class="st32" d="M38.8,10.2c-0.3-1.7-2-2.7-3.5-2.8c-1.6-0.1-3.4,0.7-3.9,2.3c-0.1,0.3,0,0.6,0.2,0.7
      c0.2,0.2,0.5,0.2,0.7,0.1c0.4-0.2,1.2-0.4,2.7-0.3c1.5,0.1,2.3,0.5,2.7,0.7C37.9,11,38,11,38.1,11c0.1,0,0.3,0,0.4-0.1
      C38.8,10.7,38.9,10.5,38.8,10.2z"/>
    <path class="st32" d="M16.9,13.8c1.5,0,2.8,1.8,2.8,4.1c0,2.2-1.2,4.1-2.8,4.1s-2.8-1.8-2.8-4.1C14.1,15.6,15.3,13.8,16.9,13.8
      M16.9,11.9c-2.6,0-4.6,2.6-4.6,6s2,6,4.6,6c2.6,0,4.6-2.6,4.6-6S19.5,11.9,16.9,11.9L16.9,11.9z"/>
    <path class="st32" d="M35.1,14.6c-1,0-1.7,1-1.7,2.4s0.8,2.4,1.7,2.4s1.7-1,1.7-2.4S36.1,14.6,35.1,14.6z"/>
    <path class="st32" d="M16.9,14.6c-1,0-1.7,1-1.7,2.4s0.8,2.4,1.7,2.4s1.7-1,1.7-2.4S17.9,14.6,16.9,14.6z"/>
    <path class="st32" d="M43.5,28.3c-0.7-1-1.9-1.7-3.2-1.8c-4.7-0.5-9.5-0.7-14.3-0.7c-4.7,0-9.5,0.2-14.3,0.7
      c-1.3,0.1-2.5,0.8-3.2,1.8c-0.7,1-1,2.3-0.7,3.5c2,8,9.5,13.6,18.1,13.6c8.6,0,16.1-5.6,18.1-13.6C44.4,30.6,44.2,29.3,43.5,28.3z
      M26,31.6c-8.2,0-12.2-1.1-14.3-3.3c0.1,0,0.1,0,0.2,0c4.6-0.4,9.3-0.7,14.1-0.7c4.8,0,9.4,0.2,14.1,0.7c0.1,0,0.1,0,0.2,0
      C38.2,30.5,34.2,31.6,26,31.6z"/>
  </svg>
  <span class="starling-text font-weight-semi-bold pt-2" :class="{ 'primary--text': active }">{{ $t('app.tools.mood_tracker.tabs.tool.fields.emotions.happy') }}</span>
</div>
</template>

<script>
export default {
  name: 'happyIcon',
  props: {
    click: {
      type: Function,
      required: false,
    },
    active: {
      type: Boolean,
      required: false,
    },
  },
};
</script>
