<template>
<div class="mood-icon anxious" @click="click('anxious')" :class="{'is-active': active}">
  <svg class="svg-icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
       x="0px" y="0px" viewBox="0 0 51 51" style="enable-background:new 0 0 51 51;" xml:space="preserve">
  <path class="st32" d="M45.5,30.6c-0.1,0.5-0.6,0.7-0.8,0.6c-0.3-0.1-0.3-0.6-0.3-0.9c0.1-0.9,0.3-2,0.2-3
    C45.3,28.1,45.7,29.5,45.5,30.6 M46.5,30.8c0.3-1.5-0.3-3.2-1.1-4.1c-0.3-0.3-0.7-0.4-1.1-0.3c-0.3,0.1-0.5,0.4-0.6,0.7
    c0,0.1,0,0.2,0,0.3c0.1,0.7,0,1.6-0.2,2.4l-0.1,0.4c-0.2,1.4,0.6,1.8,0.9,1.9c0.4,0.1,0.9,0.1,1.3-0.1
    C46.1,31.7,46.4,31.3,46.5,30.8L46.5,30.8z"/>
    <path class="st32" d="M7.1,18.5c0.3-0.7,0.6-1.2,0.6-1.2c-0.2,1-0.2,2.1-0.1,3.2c0,0.3,0,0.6-0.1,0.8c-0.1,0.1-0.2,0.2-0.3,0.3
    c-0.4,0.1-0.6-0.3-0.7-0.7C6.4,20.3,6.7,19.3,7.1,18.5 M6.2,18.1c-0.8,1.8-0.7,2.6-0.6,3c0.3,1,1,1.5,1.8,1.4
    c0.4-0.1,0.8-0.4,1-0.8c0.2-0.4,0.2-0.8,0.1-1.4c-0.1-0.9-0.1-1.9,0.1-2.8c0.1-0.5-0.2-0.9-0.6-1.1c-0.4-0.2-0.9,0-1.2,0.4
    C6.9,16.8,6.5,17.4,6.2,18.1L6.2,18.1L6.2,18.1z"/>
    <path class="st32" d="M25.5,2.5c12.7,0,23,10.3,23,23s-10.3,23-23,23c-12.7,0-23-10.3-23-23S12.8,2.5,25.5,2.5 M25.5,0.5
    c-13.8,0-25,11.2-25,25s11.2,25,25,25s25-11.2,25-25S39.3,0.5,25.5,0.5L25.5,0.5z"/>
    <path class="st32" d="M34.7,15.4c-2.9,0-5.3,2.7-5.3,5.9c0,3.3,2.4,5.9,5.3,5.9c2.9,0,5.3-2.7,5.3-5.9
    C40,18.1,37.7,15.4,34.7,15.4z M34.7,25.4c-1.9,0-3.4-1.8-3.4-4.1c0-2.2,1.5-4.1,3.4-4.1c1.9,0,3.4,1.8,3.4,4.1
    C38.1,23.6,36.6,25.4,34.7,25.4z"/>
    <ellipse class="st32" cx="34.7" cy="21.3" rx="1.9" ry="2.3"/>
    <path class="st32" d="M22.4,21.3c0-3.3-2.4-5.9-5.3-5.9c-2.9,0-5.3,2.7-5.3,5.9c0,3.3,2.4,5.9,5.3,5.9
    C20,27.3,22.4,24.6,22.4,21.3z M13.7,21.3c0-2.2,1.5-4.1,3.4-4.1c1.9,0,3.4,1.8,3.4,4.1c0,2.2-1.5,4.1-3.4,4.1
    C15.3,25.4,13.7,23.6,13.7,21.3z"/>
    <ellipse class="st32" cx="17.1" cy="21.3" rx="1.9" ry="2.3"/>
    <path class="st32" d="M33.6,40L33.6,40c-0.3-0.3-0.8-0.4-1.5-0.7c-0.6-0.2-1.3-0.4-2.1-0.8c-1.5-0.7-2.9-0.7-4.4-0.7
    c-0.5,0-1,0-1.5-0.1c-0.3,0-0.6-0.1-0.9-0.1c-1.7-0.2-3.7-0.4-5.3,1c-0.8,0.7-1,1.6-0.6,2.4c0.4,0.6,1.3,0.9,2.1,0.5
    c0.4-0.2,0.7-0.4,0.9-0.6l0.1-0.1c0.6-0.5,1.5-0.4,2.9-0.2c0.4,0.1,0.7,0.1,1.1,0.2c0.6,0.1,1.2,0.1,1.8,0.1c1,0,1.9,0,2.7,0.3
    c0.7,0.3,1,0.4,1.3,0.4c0.2,0,0.3,0.1,0.9,0.3c0.4,0.1,0.8,0.3,1.3,0.3c0.3,0,1.3-0.1,1.5-0.9C34.1,41.1,34.2,40.5,33.6,40z"/>
    <path class="st32" d="M12,14.9c0.1,0.1,0.2,0.1,0.4,0.1c0.3,0,0.6-0.1,0.8-0.3c0.9-0.9,1.9-1.9,3.7-2.4c0.5-0.1,1.1-0.3,1.4-0.6
    c0.8-0.5,0.8-1.1,0.8-1.4c-0.2-0.9-1.3-1.1-2.3-1c-0.7,0.1-2.3,0.8-2.9,1.2c-1.1,0.8-2,1.7-2.3,3.3C11.5,14.6,11.8,14.8,12,14.9z
    "/>
    <path class="st32" d="M38.1,10.6c-0.6-0.5-2.2-1.1-2.9-1.2c-1-0.2-2,0.1-2.3,1c-0.1,0.3-0.1,0.9,0.8,1.4c0.4,0.3,0.9,0.5,1.4,0.6
    c1.8,0.4,2.8,1.5,3.7,2.4c0.2,0.2,0.5,0.3,0.8,0.3c0.1,0,0.2,0,0.4-0.1c0.2-0.1,0.5-0.4,0.4-1C40.2,12.3,39.2,11.4,38.1,10.6z"/>
</svg>
  <span class="starling-text font-weight-semi-bold pt-2" :class="{ 'primary--text': active }">{{ $t('app.tools.mood_tracker.tabs.tool.fields.emotions.anxious') }}</span>
</div>
</template>

<script>
export default {
  name: 'anxiousIcon',
  props: {
    click: {
      type: Function,
      required: false,
    },
    active: {
      type: Boolean,
      required: false,
    },
  },
};
</script>
